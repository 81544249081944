export const FirebaseConfig = {
	"projectId": "mapcarslocal-ee517",
	"appId": "1:733782594534:web:0900b03ba968b55dffbc27",
	"databaseURL": "https://mapcarslocal-ee517-default-rtdb.firebaseio.com",
	"storageBucket": "mapcarslocal-ee517.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyABudfABm9V0zugUdDcdMn5XfrN1AOzQo4",
	"authDomain": "mapcarslocal-ee517.firebaseapp.com",
	"messagingSenderId": "733782594534",
	"measurementId": "G-13B2Y59VWT"
};